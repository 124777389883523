<template>

  <div class="row p-5 wrapper">
    <div class="top-bar"></div>
    <div class="col-md-7">
      <div class="title pt-5 mb-5">Offering psycho-education and <br/> preventative help</div>
      <div class="image pt-4"><img src="/static/offering-scheme.png" /></div>
      <div class="content mt-5">
        <p class="mb-2">
          Meet Momfident, your AI-powered assistant designed to provide the support you need. Built on solid academic research and expert best practices, Momfident offers evidence-based information and resources tailored for moms in the first 36 months postpartum.
        </p>
        <p>
          Whether you’re facing self-care, mental health, or parenting challenges, we’re here to help. Our virtual assistant delivers personalized conversations, practical coping strategies, and even pre-screening check-ups to guide you on when to seek specialized help. Commit to self-care with confidence, knowing Momfident is by your side.
        </p>
      </div>
    </div>
    <div class="col-md-5">
      <div class="side-image"></div>
    </div>
  </div>

</template>

<script setup>
</script>

<style lang="scss" scoped>
.wrapper {
  color: $color-purple;
  position: relative;
}

.top-bar {
  height: 200px;
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background: $color-pink-light;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.image img {
  width: 70%;
}

.side-image {
  max-width: 100%;
  height: 600px;
  background: url('~@/assets/images/phone-mock.png') center center no-repeat;
  background-size: contain;
}

.title {
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
  height: 120px;
  font-family: $font-regular;

  @media (max-width: 992px) {
    font-size: 28px;
  }
}

.content {
  font-size: 13px;
}

</style>