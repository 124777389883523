<template>
  <div class="p-5">
    <div class="container my-3">
      <div class="d-md-flex justify-content-center px-md-5">
        <div class="logo">
          <a href="https://startupfactory.bg/en/">
            <img src="/static/logo/logo-sf.png" alt="" />
          </a>
        </div>
        <div class="logo">
          <a href="https://spinoff.bg/">
            <img src="/static/logo/logo-spfbg.png" alt="" />
          </a>
        </div>
        <div class="logo">
          <a href="https://www.innovx.eu/">
            <img src="/static/logo/logo-innovx.png" alt="" />
          </a>
        </div>
        <div class="logo">
          <a href="https://iphs.eu/en/">
            <img src="/static/logo/logo-iphs.png" alt="" />
          </a>
        </div>
        <div class="logo">
          <a href="https://zonta-saintsofia.org/">
            <img src="/static/logo/logo-zonta.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="container">
      <div class="d-md-flex mx-5">
        <div class="logo-wrapper d-flex justify-content-center justify-content-md-start">
          <div class="logo me-md-3">
            <img src="/static/logo-sm.png" />
          </div>
          <div class="slogan my-3 my-md-0">
            <div>Building the future of</div>
            <div>virtual postpartum care</div>
          </div>
        </div>
        <div class="ms-auto d-md-flex">
          <div class="contact d-flex d-md-block me-5 mt-4 mt-md-0">
            <div>Follow us:</div>
            <div class="d-flex justify-content-between">
              <a class="px-2 px-md-0" href="https://www.facebook.com/profile.php?id=61561865897639">
                <img src="/static/fb-icon.png" alt="" />
              </a>
              <a class="px-2 px-md-0" href="https://www.instagram.com/momfident.io/">
                <img src="/static/insta-icon.png" alt="" />
              </a>
              <a class="px-2 px-md-0" href="https://www.linkedin.com/company/momfident">
                <img src="/static/linkedin-icon.png" alt="" />
              </a>
            </div>
          </div>
          <div class="contact me-5 mt-4 mt-md-0">
            <div>Tel:</div>
            <div>+359 896492133</div>
          </div>
          <div class="contact mt-4 mt-md-0">
            <div>Email:</div>
            <div>contact@momfident.io</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper-btm">
    <div class="container">
      <div class="d-flex mx-5">
        <div>© 2024 All Rights Reserved.</div>
        <div class="ms-auto">
          <router-link :to="{name: 'tnc'}">Terms and Conditions</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.wrapper {
  color: #fff;
  background: $color-pink-dark-2;
  padding: 50px 0;
}

.wrapper-btm {
  background: #6B6B9C;
  color: #fff;
  font-size: 12px;
  padding: 20px;
}

.logo {
  margin: 0 30px 0 30px;

  img {
    max-width: 100%;
    max-height: 100px;
  }

  @media (max-width: 692px) {
    margin-top: 30px;
    text-align: center;
  }

}
</style>