import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import TncView from "@/views/TncView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/terms-and-conditions",
    name: "tnc",
    component: TncView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
