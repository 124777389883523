<template>
  <div class="btn btn-mfd">
    <slot></slot>
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
.btn-mfd {
  background: $color-pink-dark;
  color: #fff;
  border-radius: 20px;
  padding: 10px 40px;
  font-size: 13px;

  &:hover {
    color: $color-pink-dark;
    outline: 2px solid $color-pink-dark;
  }
}
</style>
