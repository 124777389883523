<template>
  <div class="wrapper">
    <div class="container">
      <div class="title px-5 mb-5">What makes us <span>unique?</span></div>
      <div class="row px-5">
        <div class="col-lg-4 mb-4 mb-lg-0" v-for="(item) in items" v-bind:key="'item' + item">
          <div class="item p-5">
            <div class="title mb-4">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ornament-right"></div>
    <div class="ornament-left"></div>

  </div>

</template>

<script setup>
import { reactive } from 'vue';

const items = reactive([
  {
    title: 'Affordable High-Impact Care ',
    content: 'Offers accessible, affordable mental health and well-being support tailored for new mothers during the critical postpartum period.',
  },
  {
    title: 'AI-Enhanced Evidence-Based Support',
    content: 'Leverages scientifically validated data, enhanced with AI to deliver personalized, understandable evidence based resources.',
  },
  {
    title: 'Next-Level Postpartum Support',
    content: 'Improves preventative care efficiency and patient outcomes with tools for early diagnosis, patient engagement, and monitoring, while enabling better quality healthcare.',
  },
]);
</script>

<style lang="scss" scoped>
.wrapper {
  color: $color-purple;
  position: relative;
  background: #FFEFF2;
  padding: 80px 0;
}

.item {
  background: #fff;
  border-radius: 30px;
  height: 370px;

  @media (max-width: 692px) {
    height: 350px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    height: 300px;
  }

  .title {
    font-weight: 500;
    color: $color-pink-dark-2;
  }

  .content {
    font-size: 13px;
  }
}

.ornament-right,
.ornament-left {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 30%;
  height: 50%;
}

.ornament-right {
  background: url('~@/assets/images/leaf-inv.png') no-repeat;
  background-size: contain;
  background-position: right bottom;
  right: 0;
}

.ornament-left {
  background: url('~@/assets/images/leaf.png') no-repeat;
  background-size: contain;
  background-position: left bottom;
  left: 0;
}

.container {
  position: relative;
  z-index: 999;
}

.title {
  font-size: 25px;
  font-weight: 600;
  line-height: 1;
  color: #6B6B9C;
  span {
    color: #FC678B;
  }
}
</style>