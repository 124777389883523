<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'modal-backdrop': true,
     'd-block': isOpen,
      'd-none': !isOpen,
    }" @click="closeModalIfBackdrop($event)">
    <div v-bind:class="{ 'modal': true, 'd-flex': isOpen }">
      <div v-bind:class="{'modal-dialog': true, 'modal-size-large': size === 'large'}">
        <div class="modal-content">
          <div class="modal-header" v-if="title">
            <div class="modal-title" >{{ title }}</div>
          </div>
          <div @click="closeModal()" class="pointer close-button">
            <ModalCloseBtn/>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>