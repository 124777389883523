
export default {
    namespaced: true,
    state: {
        isSignUpModalOpen: false,
    },
    mutations: {
        OPEN_MODAL(state) {
            state.isSignUpModalOpen = true;
        },
        CLOSE_MODAL(state) {
            state.isSignUpModalOpen = false;
        }
    },
    actions: {
        async openSignUpModal({commit}) {
            commit('OPEN_MODAL')
        },
        async closeSignUpModal({commit}) {
            commit('CLOSE_MODAL')
        },
        
    }
};
