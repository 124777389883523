<template>
  <div class="wrapper">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-checklist.png" alt="" />
          </div>
          <div class="title mb-3">Efficient Patient <br /> Management</div>
          <div class="content">
            <p>Provide preventative care through ongoing screening and real-time monitoring.</p>
            <p>Save time with already educated patients and access to comprehensive patient records.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-5">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-heart.png" alt="" />
          </div>
          <div class="title mb-3">Continuous engagement and<br /> support</div>
          <div class="content">
            <p>Improve engagement through personalized education and ongoing evidence-based support</p>
            <p>Extend support across major stages of women's health including fertility, pregnancy and in the future menopause.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-items.png" alt="" />
          </div>
          <div class="title mb-3">Business growth<br /> opportunities</div>
          <div class="content">
            <p>Be discovered and specialize in an untapped niche as a certified specialist</p>
            <p>Use as a white-label solution or rebrand for your organization to facilitate cross-selling and upselling.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="item">
          <div class="image mb-3">
            <img src="/static/icon-smart.png" alt="" />
          </div>
          <div class="title mb-3">Data-driven <br /> personalized care</div>
          <div class="content">
            <p>Integrate your own health data to provide holistic health support to individual patient needs.</p>
            <p>Gain insights and improve care through data-driven decisions.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.wrapper {
  color: $color-purple;
}

.image {
  text-align: center;

  img {
    width: 135px;
  }
}

.item {
  padding: 0 50px;
  @media (max-width: 992px) {
    margin-bottom: 70px;
  }
}

.title {
  font-family: $font-semi-bold;
  font-size: 14px;
  text-align: center;

}

.content {
  font-family: $font-thin;
  font-size: 13px;

  @media (max-width: 992px) {
    text-align: center;
  }
}

</style>