import ModalCloseBtn from "./ModalCloseBtn";

export default {
    name: 'ModalComponent',
    components: {
        ModalCloseBtn,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'regular',
        }
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        closeModalIfBackdrop(event) {
            if (event.target.classList.contains('modal')) {
                this.closeModal();
            }
        },
        closeModal() {
            this.$store.dispatch("utils/closeModal", this.$props.name);
            this.$emit('onClose');
        },
    }
}