<template>
    <router-view />

  <ModalComponent
    :name="'signUp'"
    :is-open="$store.state.products.isSignUpModalOpen"
    size="large"
    :title="'Subscribe'" @onClose="closeModal">
    <div v-if="sent" class="text-success p-2 w-100 text-center">You have subscribed successfully!</div>
    <div v-if="err" class="text-danger p-2 w-100 text-center">Error! Please try again later.</div>
    <div v-else>
      <div class="mb-2">
        <label>First Name:</label>
      </div>
      <div class="mb-2">
        <input type="text" v-model="firstName" :class="{'form-error': formErrors.includes('firstName')}">
      </div>

      <div class="mb-2">
        <label>Last Name:</label>
      </div>
      <div class="mb-2">
        <input type="text" v-model="lastName" :class="{'form-error': formErrors.includes('lastName')}">
      </div>

      <div class="mb-2">
        <label>Country:</label>
      </div>
      <div class="mb-2">
        <input type="text" v-model="country">
      </div>

      <div class="mb-2">
        <label>Email:</label>
      </div>
      <div class="mb-2">
        <input type="text" v-model="email" :class="{'form-error': formErrors.includes('email')}">
      </div>

      <div class="mb-2">
        <label>Phone:</label>
      </div>
      <div class="mb-2">
        <input type="text" v-model="phone">
      </div>

      <div class="mt-4 d-flex tnc-box">
        <div>
          <input type="checkbox" v-model="tnc">
        </div>
        <div v-bind:class="{'error': formErrors.includes('tnc')}">By signing up, you agree to our <router-link :to="{name: 'tnc'}">Terms and Conditions</router-link>
          and consent to receiving news and updates. You also agree to receive our feedback forms/surveys and be among the first to access our initial prototype.
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-center">
        <MainButton @click="sendForm">Subscribe</MainButton>
      </div>
    </div>
  </ModalComponent>
</template>

<style lang="scss">
label {
  font-size: 14px;
  color: $color-purple;
}
input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-error {
  border: 1px solid #a62a2a;
  background: #fffafa;
}
.tnc-box {
  font-size: 12px;
  input {
    width: 25px;
  }
  .error, a:link, a:hover, a:active, a:visited {
    color: #a62a2a;
  }
}
</style>
<script setup>
import ModalComponent from '@/components/Components/ModalComponent/ModalComponent';
import MainButton from '@/components/Common/Buttons/MainButton';
import { ref } from 'vue';
import axios from 'axios';
import store from '@/store';

const formErrors = ref([])

const firstName = ref(null)
const lastName = ref(null)
const country = ref(null)
const email = ref(null)
const phone = ref(null)
const tnc = ref(null)
const sent = ref(false)
const err = ref(false)

async function closeModal() {
  await store.dispatch('products/closeSignUpModal')
  sent.value = false
  firstName.value = ''
  lastName.value = ''
  country.value = ''
  phone.value = ''
  email.value = ''
  formErrors.value = [];
}
async function sendForm() {
  try {
    sent.value = false;
    err.value = false;
    formErrors.value = [];

    if (tnc.value !== true) {
      formErrors.value.push('tnc')
    }

    if (firstName.value === null || firstName.value.length < 2) {
      formErrors.value.push('firstName')
    }

    if (lastName.value === null || lastName.value.length < 2) {
      formErrors.value.push('lastName')
    }

    const mailRegex = /\S+@\S+\.\S+/;
    if (email.value === null || email.value === '' || !mailRegex.test(email.value)) {
      formErrors.value.push('email');
    }

    if (formErrors.value.length > 0) {
      return Promise.resolve();
    }

    await axios.post('/api/subscribe', {
      firstName: firstName.value,
      lastName: lastName.value,
      country: country.value,
      phone: phone.value,
      email: email.value,
    })

    formErrors.value = [];
    sent.value = true
  } catch (err) {
    console.log('----> err');
    console.log(err);
    err.value = true
  }
}
</script>
