<template>
  <div class="primary-hero row mt-5 p-5">
    <div class="col-md-12 title mb-5">You are not alone</div>
    <div class="col-md-4 stat">
      <img src="/static/stat-1.png" alt=""/>
    </div>
    <div class="col-md-4 stat">
      <img src="/static/stat-2.png" alt=""/>
    </div>
    <div class="col-md-4 stat">
      <img src="/static/stat-3.png" alt=""/>
    </div>
    <div class="col-md-12 d-flex justify-content-center mt-4">
      <div class="bullet-points">
        <div>
          Traditional healthcare focuses on physical recovery, often neglecting your mental and emotional well-being.
        </div>
        <div>
          Family and societal pressures can leave you feeling overwhelmed.
        </div>
        <div>
          You might not know when or where to seek help. Many moms wait until issues are critical
        </div>
        <div>
          Traditional therapy can be costly, inaccessible, and demands significant commitment.
        </div>
        <div>
          Forums and social media can provide conflicting and unreliable advice, often causing more harm.
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
</script>

<style lang="scss" scoped>

.primary-hero {
  background: $color-pink-light;
  color: $color-purple;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
}

.stat img {
  width: 100%;
}

.bullet-points {
  font-weight: 100;
  width: 80%;
  font-size: 14px;
  
  div {
    text-align: center;
    &:before{
      content:'\00b7';
      font-size:14px;
      line-height: 1;
    }
  }
}

.title {
  font-size: 25px;
  font-weight: 400;
  line-height: 1;
  color: #6B6B9C;
  text-align: center;
}
</style>